import Button from '@components/Button';
import SEO from '@components/seo';
import ProjectsContext from '@context/ProjectsContext';
import priceFormat from '@src/helpers/priceFormat';
import ScrollInView from '@src/utility/ScrollInView';
import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ContentEditable from 'react-contenteditable';
import ErrorIcon from '../../../static/images/icons/icon-error-black.svg';
import DeleteConfirmation from '../DeleteConfirmation';
import * as styles from '../Profile/styles.module.scss';
import Quotes from '../Quotes';
import useQuotes from '../Quotes/useQuotes';
import SalesRep from '../SalesRep';
import PrintHeading from './PrintHeading';
import { graphql, useStaticQuery } from 'gatsby';
import ProjectCard from './ProjectCard';
import ProjectEdit from './ProjectEdit';
import ProjectRead from './ProjectRead';
import RFQPopup from './RFQPopup';
import CustomProductsAlert from './CustomProductsAlert';
import PortableText from '@utility/PortableText';
import { useLocation } from '@reach/router';

const DATE_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric' };

const Project = ({ projectId, authUser }) => {
  const accountData = useStaticQuery(
    graphql`
      query {
        allSanityAccount {
          edges {
            node {
              _rawCustomProdsNoteNonTrade(resolveReferences: { maxDepth: 10 })
              _rawCustomProdsNoteVerifiedTrade(
                resolveReferences: { maxDepth: 10 }
              )
            }
          }
        }
        sanityConfigurator {
          customMarking
        }
      }
    `,
  );

  const {
    getCurrentProject,
    updateProject,
    loading,
    deleteProject,
    reps,
    repsLoading,
  } = useContext(ProjectsContext);
  const location = useLocation();
  const generatedDate = new Date().toLocaleDateString('en-US', DATE_OPTIONS);
  const ref = useRef();
  const [quoteSuccess, setQuoteSuccess] = useState(false);
  const [project, setProject] = useState(null);
  const [error, setError] = useState(false);
  const [productsError, setProductsError] = useState(false);
  const [productsArray, setProductsArray] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [allowQuoteRequest, setAllowQuoteRequest] = useState(false);
  const [quoteHelpText, setQuoteHelpText] = useState(
    'Complete your project details to request a quote',
  );
  const [subTotal, setSubTotal] = useState(0);
  const [rfqPopupOpen, setRfqPopupOpen] = useState(false);
  const [customProductsAlertOpen, setCustomProductsAlert] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [hasCustomProducts, setCustomProductsExist] = useState(false);
  const { _rawCustomProdsNoteNonTrade, _rawCustomProdsNoteVerifiedTrade } =
    accountData.allSanityAccount.edges[0].node;
  const { customMarking } = accountData.sanityConfigurator;
  const customFinishRegex = new RegExp(
    `(${customMarking
      .split(',')
      .map((e) => e.trim())
      .join('|')})`,
    'i',
  );

  const {
    createQuote,
    quotes,
    loading: loadingQuote,
  } = useQuotes(projectId, project);

  const quoteRequestState = useCallback(() => {
    const projectHasBeenUpdatedSinceLastQuote =
      project?.updated?.valueOf() > quotes[0]?.created?.valueOf();
    if (projectHasBeenUpdatedSinceLastQuote || quotes[0] === undefined) {
      // If there is recent changes to the project or no quotes have been made allow request
      setAllowQuoteRequest(true);
    }
    if (project?.products && !Object.keys(project?.products).length) {
      // If no products disallow request
      setAllowQuoteRequest(false);
      setQuoteHelpText('Add a product before requesting a quote');
    }
    if (project?.application_type === undefined) {
      // If project is in edit mode disallow request
      setAllowQuoteRequest(false);
    }
  }, [
    project?.updated?.valueOf,
    quotes,
    project?.products,
    project?.application_type,
  ]);

  useEffect(() => {
    if (!loading) {
      const currentProj = getCurrentProject(projectId);
      if (currentProj && currentProj.length) {
        setProject(currentProj[0]);
        if (project) {
          if (project.application_type === undefined) {
            // if the user is visiting the project
            // detail page for the first time coming from a pdp
            // then present them with the edit form first
            // application_type will not exist until the user
            // submits the form
            setEditMode(true);
          }
          quoteRequestState();
        } else {
          setEditMode(false);
        }
        // Request Quote Logic
      }
      if (currentProj && !currentProj.length) {
        // if projectId does not match a users project
        // within the getCurrentProject filter function in
        // the ProjectsProvider than display an error
        setError(true);
      }
    }
    return () => {
      setEditMode(false);
    };
  }, [getCurrentProject, projectId, loading, project, quoteRequestState]);

  useIsomorphicLayoutEffect(() => {
    setCustomProductsExist(false);
    let subTotal = 0;

    const productsDataArray = [];

    if (project?.products) {
      Promise.all(
        Object.values(project.products).map((product) => {
          const slug = product.slug;
          return fetch(slug)
            .then((res) => res.text())
            .then((str) => {
              let data = {};
              // Initialize the DOM parser
              const parser = new DOMParser();

              // Parse the text
              const doc = parser.parseFromString(str, 'text/html');
              const cardElement =
                doc.getElementById('js-project-card')?.innerHTML;

              if (cardElement) {
                data =
                  JSON.parse(
                    doc.getElementById('js-project-card')?.innerHTML,
                  ) ?? {};
              }

              if (cardElement) {
                productsDataArray.push({
                  ...data,
                  quantity: product.quantity,
                  slug: product.slug,
                });

                if (!data.price) {
                  setCustomProductsExist(true);
                }

                subTotal +=
                  data[authUser?.user_trade ? 'discount_price' : 'price'] *
                  product.quantity;
              }
            });
        }),
      )
        .then(() => {
          setSubTotal(subTotal);
          setProductsArray(productsDataArray);
        })
        .catch((err) => {
          console.error(err);
          setProductsError(true);
        });
    }
  }, [project]);

  const onNameSubmit = (e) => {
    // if there is nothing in the field then return project
    // name to current name
    if (e.target.innerText.replace(/\s/g, '') === '') {
      e.target.innerText = project.name;
      return null;
    }
    // if the current name is different from the current
    // than update the project name
    if (e.target.innerText.trim() !== project.name) {
      updateProject(projectId, {
        name: e.target.innerText.trim(),
      });
    }
  };

  const renderProjectName = () => (
    <div className={cn(styles.heading, 'print:hidden')}>
      <ContentEditable
        html={project.name}
        className={styles.headingEditable}
        onBlur={onNameSubmit}
      />
      <h1 className="sr-only">{project.name}</h1>
    </div>
  );

  const scrollToQuotes = () => {
    if (ref.current) {
      const pos = window.pageYOffset + ref.current.getBoundingClientRect().top;
      window.scrollTo({
        top: pos - 12,
        behavior: 'smooth',
      });
    }
  };

  const requestQuote = () => {
    setQuoteHelpText('Update your project to request a new quote');
    setQuoteSuccess(true);
    setTimeout(() => {
      setQuoteSuccess(false);
      setAllowQuoteRequest(false);
      createQuote(scrollToQuotes);
    }, 2000);
  };

  const addAllToCart = () => {
    const hasNullPrice = productsArray.some(
      (product) => product.price === null,
    );

    const addProductPromises = productsArray
      .filter((product) => product.price !== null && product.name && !customFinishRegex.test(product.slug))
      .map((product) => {
        let descArray = [];
        let productUrl = `${location.origin}${product.slug}`;
        let {
          minLeadTime,
          maxLeadTime,
          leadTime10,
          leadTime25,
          leadTime50,
          leadTime100,
          leadTime200,
          shipDateOverride,
          addedLeadTimeDays,
        } = product;

        Object.keys(product.description).map((i) => {
          descArray.push({
            type: 'textbox',
            name: product.description[i].attr,
            value: null,
            options: '',
            required: false,
          });
          descArray.push({
            type: 'textbox',
            name: product.description[i].value,
            value: null,
            options: '',
            required: false,
          });
        });

        let customAttributes = [
          {
            type: 'hidden',
            name: 'Actual Price',
            value: product.price.toString(),
            options: '',
            required: false,
          },
          {
            type: 'hidden',
            name: 'isUserTrade',
            value: (!!authUser?.user_trade).toString(),
            options: '',
            required: false,
          },
          ...descArray,
          ...Object.entries({
            minLeadTime,
            maxLeadTime,
            leadTime10,
            leadTime25,
            leadTime50,
            leadTime100,
            leadTime200,
            shipDateOverride,
            addedLeadTimeDays,
          }).map(([key, value]) => ({
            type: 'hidden',
            name: key,
            value: value ? value.toString() : '',
            options: '',
            required: false,
          })),
          {
            type: 'textbox',
            name: 'Estimated Ship Date',
            value: null,
            options: '',
            required: false,
          },
          {
            type: 'textbox',
            name: '',
            value: null,
            options: '',
            required: false,
          },
        ];

        let data = {
          id: product.sku + (!!authUser?.user_trade ? '-discount' : ''),
          description: product.sku,
          image: product.image,
          name: product.name,
          stackable: true,
          price: !!authUser?.user_trade
            ? product.discount_price
            : product.price,
          quantity: product.quantity,
          url: productUrl,
          dimensions: {
            weight: product.weight,
          },
          customFields: customAttributes,
        };

        setTimeout(() => {
          return Snipcart.api.cart.items.add(data);
        }, 4000);
      });

    Promise.all(addProductPromises).then(() => {
      setAddedToCart(true);
      window.scrollBy(0, 1); //to prevent the navbar drop due to interactive button height change

      if (hasNullPrice) {
        //show custom product popup
        setCustomProductsAlert(true);
      }

      setTimeout(() => {
        setAddedToCart(false);
      }, 2000);
    });
  };

  const renderQuoteButton = (showPrint) => (
    <>
      {showPrint && (
        <Button
          className={cn(
            'btn-default btn-black btn-lg--max-width block text-center btn-interaction print:hidden',
            { 'is-loading': addedToCart },
            { 'btn-black-disabled': !productsArray.length },
          )}
          aria-label="Add all to cart"
          disabled={!productsArray.length}
          onClick={() => addAllToCart()}
        >
          <span>Add all to cart</span>
          <span>Added to cart</span>
        </Button>
      )}
      {authUser?.user_trade && (
        <Button
          className={cn(
            'btn-default btn-black btn-lg--max-width block text-center btn-interaction print:hidden',
            { 'is-loading': quoteSuccess },
            { 'btn-black-disabled': !allowQuoteRequest },
          )}
          disabled={!allowQuoteRequest}
          onClick={() => {
            setRfqPopupOpen(true);
          }}
          aria-label="Request Quote"
        >
          <span>Request Quote</span>
          <span>Quote Request Sent</span>
        </Button>
      )}
      {showPrint && (
        <Button
          className="btn-default btn-secondary--upper border border-mono-500 block text-center print:hidden"
          onClick={() => window.print()}
          aria-label="Print Project"
        >
          <span>Print Project</span>
        </Button>
      )}
      {!allowQuoteRequest && authUser?.user_trade && (
        <span className="type-sans-030 flex mt-2 print:hidden">
          <img src={ErrorIcon} alt="Alert icon" width="12" className="mr-2" />
          {quoteHelpText}
        </span>
      )}
    </>
  );

  return (
    <div className="print-page">
      <SEO title="My Project">
        <meta name="robots" content="noindex" />
      </SEO>
      {error && <h1 className="type-sans-930 mb-20">Project Not Found</h1>}

      {!error &&
        (project ? (
          <div>
            <PrintHeading />
            {renderProjectName()}

            <div
              className={cn(styles.listProjects, 'print:w-4/5 print:ml-auto')}
            >
              <div className={styles.listRow}>
                <div className={styles.listFirstItem}>
                  <h1 className="hidden print:block type-sans-830 mb-4">
                    {project.name}
                  </h1>
                  <h2 className="type-sans-630">Project Details</h2>
                </div>
                <div className={styles.listLastItem}>{renderQuoteButton()}</div>
              </div>
              <ScrollInView animateType="fadeIn" key={`transition${editMode}`}>
                {project &&
                  (!editMode ? (
                    <ProjectRead
                      project={project}
                      projectId={projectId}
                      setEditMode={setEditMode}
                    />
                  ) : (
                    <ProjectEdit
                      project={project}
                      projectId={projectId}
                      setEditMode={setEditMode}
                    />
                  ))}
              </ScrollInView>
            </div>
            <SalesRep reps={reps} repsLoading={repsLoading} />
            {project?.products && (
              <div className="print:break-before-page print:w-4/5 print:ml-auto">
                <h2 className="type-sans-630 mt-16 mb-6 print:mt-0">
                  Products
                </h2>
                {!Object.keys(project.products).length && (
                  <p className="type-sans-330 text-mono-500 mr-12">
                    You have no Products associated to this Project.
                    <br /> To create a Quote go to the product page of your
                    choice and click &apos;Add to Project Quote&apos;.
                  </p>
                )}
                {Object.keys(project.products)
                  .sort()
                  .map((key, i, array) => (
                    <ProjectCard
                      key={key}
                      sku={key}
                      projectId={projectId}
                      productData={project.products[key]}
                      isCustom={customFinishRegex.test(
                        project.products[key].slug,
                      )}
                      isTradeUser={authUser?.user_trade === true}
                    />
                  ))}
              </div>
            )}
            <div className="flex flex-col-reverse gap-10 md:gap-6 md:flex-row md:justify-between mt-10 mb-24 print:mb-0">
              <div className="md:w-1/2 flex flex-col gap-2">
                {renderQuoteButton(true)}
              </div>
              {project?.products && !hasCustomProducts ? (
                <div className="pr-3 print:ml-auto">
                  {!productsError ? (
                    subTotal > 0 ? (
                      <>
                        <div className="flex md:justify-between gap-6">
                          <h3 className="type-sans-530 !leading-none">
                            Subtotal
                          </h3>
                          <p className="type-sans-530 !leading-none">
                            {priceFormat(+subTotal)}
                          </p>
                        </div>
                        <p className="type-sans-030 mt-3">
                          Tax and shipping not included
                        </p>
                      </>
                    ) : null
                  ) : (
                    <p className="type-sans-140">
                      Please remove outdated item to see subtotal
                    </p>
                  )}
                </div>
              ) : null}
              {hasCustomProducts && (
                <p
                  className={`type-sans-140 md:max-w-1/2 md:w-1/2 print:w-full print:text-right richtext-content`}
                >
                  <PortableText
                    blocks={
                      authUser?.user_trade
                        ? _rawCustomProdsNoteVerifiedTrade?.richText
                        : _rawCustomProdsNoteNonTrade?.richText
                    }
                  />
                </p>
              )}
            </div>
            <div className="hidden print:fixed print:bottom-0 print:right-0 print:block print:m-1">
              Generate Date: {generatedDate}
            </div>
            {authUser?.user_trade && (
              <Quotes loading={loadingQuote} ref={ref} quotes={quotes} />
            )}
            <DeleteConfirmation
              onSuccess={() => deleteProject(projectId)}
              text="Delete Project"
            />
            <RFQPopup
              isOpen={rfqPopupOpen}
              variables={{
                email: project?.alternativeEmail ?? authUser.email,
              }}
              onClose={() => {
                setRfqPopupOpen(false);
              }}
              requestQuoteFunction={requestQuote}
            />
            <CustomProductsAlert
              isOpen={customProductsAlertOpen}
              onClose={() => setCustomProductsAlert(false)}
            />
          </div>
        ) : null)}
    </div>
  );
};

export default Project;
